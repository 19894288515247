import axios from "axios";
import { updateLocale } from "moment-timezone";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Source from "../Source";
import belance from "../assets/icon/belance.png";
import ComboBox from "./forms/ComboBox";
const formatNumber = (number) => {
  return number.toLocaleString(); // Formats number with thousand separators
};
// import {ComboBoxComponent} from '@syncfusion/ej2-react-dropdowns'
export default function Cach({
  fetchTotalRecords,
  totals,
  setTotals,
  setMoney1
}) {
  // const [record,setRecord]=useState([]);
  const [money,setMoney]=useState([]);
  useEffect(()=>{
    axios.get(Source.getAddress() + '/api/money', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      console.log(res)
      setMoney(res.data);
      setMoney1(res.data);
    }).catch((err)=>{
      console.log(err);
    });
  },[]);
  let record = [];
  record = money;
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div className="container">
      <div className="balance-container row mt-4 p-4 pb-0 mb-0">
        <h1 className="fw-bold fs-1 p-1">Cach</h1>
        {/* {accountbelance && accountbelance.map((row) => ( */}
        {record &&
          record.map((row) => {
            return (
              <div className="col-12 col-md-3 mb-2" key={row.id}>
                <div
                  className="ps-2 pe-2 rounded-3"
                  style={{
                    backgroundColor: "white",
                    borderTop: "3px solid #4a5cf2",
                  }}
                >
                  <div className="d-flex py-4">
                    <img
                      src={belance}
                      className="position-sticky row p-1"
                      style={{ width: "7rem" }}
                    />
                    <div className="row p-1 ps-4">
                      <h5 className="mb-2 fw-bold text-capitalize">
                        {row.name}
                        Total transactions :{" "}
                        {totals[row.id] !== undefined
                          ? formatNumber(totals[row.id])
                          : "Loading..."}
                      </h5>
                      <p className="mb-2 fw-bold">
                        Total : {formatNumber(row.cach)} Available Cash
                      </p>
                    </div>
                  </div>
                  <a
                    type="submit"
                    className="btn btn-info mb-1 p-1 self-center"
                    style={{ color: "white" }}
                    href="/cach/report"
                    onClick={()=>{
                      localStorage.setItem('cashid',row.id);
                      localStorage.setItem('cashName',row.name);
                    }}
                  >
                    See all transaction
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
