import axios from "axios";
import React, { useState, useRef, useEffect } from "react";

export default function AddMoney({
  setAdd,
  title,
  Addmoney,
  error,
  add,
  setmoney,
  money,
  setError,
  inputRef,
}) {
  const handle = (e) => {
    setmoney({
      ...money,
      [e.target.name]: e.target.value,
      // user: parseInt(localStorage.getItem("userTokenid")),
    });
  };
  const buttonRef = useRef(null);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // Check if the Enter key was pressed
      e.preventDefault(); // Prevent default action if needed
      if (buttonRef.current && add) {
        buttonRef.current.click(); // Programmatically click the button
      }
    }
  };
  return (
    <div
      className={`rounded-4 row g-2 popup m-4 mb-0 ${add && "show"}`}
      style={{ width: "26rem" }}
    >
      <h1
        className="text-center rounded m-0 p-2 w-100 text-light fs-4"
        style={{ backgroundColor: "var(--bs-info)" }}
      >
        Add Money Type
      </h1>
      <div class="col-md-12">
        {" "}
        <label htmlFor="exampleInputEmail1" className="">
          Money Name
        </label>
        <input
          // autoFocus
          ref={inputRef}
          onKeyDown={handleKeyDown}
          type="text"
          name="name"
          onChange={handle}
          value={money.name}
          className="form-control w-100"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          style={{ width: "70%" }}
        />
      </div>
      <div className="col-10 ms-4 m-10 mt-5 ps-3 d-flex">
        <a
          className="text-center form-control btn btn-danger text-light me-1"
          style={{ margin: "auto", width: "150px" }}
          onClick={() => {
            setAdd(false);
            setError(false);
          }}
        >
          Cancel
        </a>
        <a
          className="text-center btn form-control btn-success text-light ms-1"
          ref={buttonRef}
          style={{ margin: "auto", width: "150px" }}
          onClick={() => Addmoney(money)}
        >
          Submit
        </a>
      </div>
    </div>
  );
}