import axios from "axios";
import React, { useState, useEffect, useMemo,useRef } from "react";
import DataTable from "react-data-table-component";
import { gregorianToJalali } from "shamsi-date-converter";
import Source from "../../Source";
import AddItem from "./forms/AddItem";
export default function Items() {
  const [records,setRecords]=useState([]);
  const [AddItemModal,setAddItemModal]=useState(false);
  useEffect(()=>{
    const fetchItem = async () => {
      const token = localStorage.getItem('access');
      if (!token) return;
      const fetchWithToken = async (token) => {
        try {
          const response = await axios.get(Source.getAddress()+'/api/item', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          // console.log(response.data);
          setRecords(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            const refreshToken = localStorage.getItem('refresh');
            if (!refreshToken) return;

            try {
              const refreshResponse = await axios.post(Source.getAddress() + '/api/token/refresh', {
                refresh: refreshToken,
              });
              const newAccessToken = refreshResponse.data.access;
              localStorage.setItem('access', newAccessToken);
              await fetchWithToken(newAccessToken);
            } catch (refreshError) {
              console.error('Error refreshing access token:', refreshError);
            }
          } else {
            console.error('Error fetching accounts:', error);
          }
        }
      };

      await fetchWithToken(token);
    };
    fetchItem();
  })
  
  const columns = [
    { name: "Id", selector: (row) => row.id, sortable: true 
      ,style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    { name: "Item Type", selector: (row) => row.item_type_name, sortable: true 
      ,style: {
        width: "1px",
        minWidth: "1px",
      },
    }
    ,
    { name: "Name", selector: (row) => row.name, sortable: true 
      ,style: {
        width: "1px",
        minWidth: "1px",
      },
    }
  ,
  { name: "Serial Number", selector: (row) => row.serial_number, sortable: true 
    ,style: {
      width: "1px",
      minWidth: "1px",
    },
  }
,
    { name: "Date Creation", selector: (row) => row.date_creation, sortable: true },
    { name: "Description", selector: (row) => row.description, sortable: true },
    { name: "Add By", selector: (row) => row.user_name, sortable: true },
  ];
  return (
    <div
      className={`w-100 ${"iransans"}`}
    >
      <div className="w-75 m-auto mt-5 m-5" style={{ height: "100%" }}>
      <button
          type="submit"
          className="btn btn-info mb-1 p-1"
          style={{ width: "100px" }}
          onClick={() => setAddItemModal(true)}
        >
          Add
        </button>
        <div className="bg-light d-flex justify-content-lg-between"
          style={{borderTop: "5px solid #4a5cf2"}}
        
        >
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
          <h2 className="m-2 fw-bold mb-4">Items</h2>
            <select
              id=""
              name="gender"
            //   onChange={ChangeUser}
              class="form-select h-50 m-auto"
              style={{ width: "20%" }}
            //   value={select_user}
            >
              <option value={'all users'} selected>All Users</option>
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            // onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>
        <DataTable
          // title="Customers"
          columns={columns}
          data={records}
          // onRowClicked={handleRowClick}
          striped
          responsive
          highlightOnHover
          pagination
          // noHeader
          // noTableHead
        />
<AddItem
AddItemModal={AddItemModal}
close={()=>setAddItemModal(false)}
/>
      </div>
    </div>
  );
}