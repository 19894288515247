import axios from "axios";
import React, { useState, useEffect, useMemo,useRef } from "react";
import DataTable from "react-data-table-component";
import { gregorianToJalali } from "shamsi-date-converter";
import Source from "../../Source";
import AddItemType from "./forms/AddItemType";
import * as XLSX from "xlsx"; // Import the xlsx library
import AddStock from "../../assets/icon/AddStock.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default function ItemType() {
  const [openadd,setopenadd]=useState(false);
  const [records,setRecords]=useState();
  const inputRef=useRef();
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(records); // Convert JSON to sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Item Types");
    XLSX.writeFile(workbook, "ItemTypes.xlsx"); // File name
  };
  useEffect(()=>{
    const fetchItemtype = async () => {
      const token = localStorage.getItem('access');
      if (!token) return;
      const fetchWithToken = async (token) => {
        try {
          const response = await axios.get(Source.getAddress()+'/api/itemtype', {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });
          setRecords(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            const refreshToken = localStorage.getItem('refresh');
            if (!refreshToken) return;

            try {
              const refreshResponse = await axios.post(Source.getAddress() + '/api/token/refresh', {
                refresh: refreshToken,
              });
              const newAccessToken = refreshResponse.data.access;
              localStorage.setItem('access', newAccessToken);
              await fetchWithToken(newAccessToken);
            } catch (refreshError) {
              console.error('Error refreshing access token:', refreshError);
            }
          } else {
            console.error('Error fetching accounts:', error);
          }
        }
      };

      await fetchWithToken(token);
    };
    fetchItemtype();
  },[]);
  console.log(records);
  const columns = [
    { name: "Id", selector: (row) => row.id, sortable: true 
      ,style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.picture===null ? AddStock : row.picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Mesurment", selector: (row) => row.measuring, sortable: true },
  ];
  const handleAdd=()=>{
    inputRef.current.select();
    setopenadd(true);
  }
  return (
    <div
      className={`w-100 ${"iransans"}`}
    >
      <div className="w-50 m-auto mt-5 m-5" style={{ height: "100%" }}>
      <button
        onClick={handleAdd}
        type="submit"
        className="btn btn-info mb-1 p-1 text-white"
        style={{ width: "100px" }}
      >
        Add
      </button>
        <div className="bg-light d-flex justify-content-lg-between"
          style={{borderTop: "5px solid #4a5cf2"}}
        
        >
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
          <h2 className="m-2 fw-bold mb-4">Item type</h2>
            <select
              id=""
              name="gender"
            //   onChange={ChangeUser}
              class="form-select h-50 m-auto"
              style={{ width: "20%" }}
            //   value={select_user}
            >
              <option value={'all users'} selected>All Users</option>
              {/* {user.map((item) =>(
              <option value={item.name} selected>{item.name}</option>
              ))} */}
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            // onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>

        <DataTable
          // title="Customers"
          columns={columns}
          data={records}
          // onRowClicked={handleRowClick}
          striped
          responsive
          highlightOnHover
          pagination
        />
      </div>
      <AddItemType
       setRecords={setRecords} records={records} open={openadd} close={()=>{setopenadd(false)}} inputRef={inputRef}
      />
    </div>
  );
}